.board-fa-big {
  font-size: 11em !important;
  color: #039be5;
  width: auto;
  height: auto;
  line-height: inherit;
}

.sidenav-headline {
  color: white;
  font-weight: bolder;
  padding: 15px;
  background-color: #039be5;
}


.views {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 330px;

  .view {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;

    > .header {
      flex: 0 1 auto;
      height: 64px;
      min-height: 64px;
      border-bottom: 1px solid;
    }

    > .content {
      flex: 1 1 auto;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
}

.nav-item > .nav-option {
  height: 40px;
  padding: 0 12px 0 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.nav-item > .nav-option:hover {
  background-color: #d2c7c7;
}


//omt-workflow-board-sidenav {
//    display: flex;
//    flex-direction: column;
//    flex: 1 0 auto;
//    height: 100%;
//
//    .views {
//        display: flex;
//        flex-direction: column;
//        position: relative;
//        overflow: hidden;
//        height: 100%;
//
//        .view {
//            position: absolute;
//            width: 100%;
//            height: 100%;
//            bottom: 0;
//            left: 0;
//            right: 0;
//            top: 0;
//            display: flex;
//            flex-direction: column;
//
//            > .header {
//                flex: 0 1 auto;
//                height: 64px;
//                min-height: 64px;
//                border-bottom: 1px solid;
//            }
//
//            > .content {
//                flex: 1 1 auto;
//                overflow-y: auto;
//                -webkit-overflow-scrolling: touch;
//            }
//        }
//    }
//}
