omt-time-board {
  width: 100%
}

.calender-month-avatar {
  margin-top: 11px;
  margin-left: -10px;
  margin-right: 5px;
  width: 17px;
  height: 17px;
}
